import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtils } from '../utils/http-utils';
import { Company } from '../models/company.class';
import { UserRoles } from '../models/user.class';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private triggerGetUserAndSetCompanies = new Subject<void>(); // Subject specifically for triggering the getUserAndSetCompanies function inside main.component
  public getUserAndSetCompanies$ =
    this.triggerGetUserAndSetCompanies.asObservable(); // Observable that main.component can subscribe to

  private logoutSubject = new Subject<void>();
  public logout$ = this.logoutSubject.asObservable();

  private redirectUrl: string | null= null;

  constructor(private httpClient: HttpClient) {}

  login(username: string, password: string) {
    return this.httpClient
      .post<any>(`${environment.serviceUrl}/login`, { username, password })
      .pipe(
        tap((user) => {
          this.setToken(user.accessToken);
          this.setUser(user);
          if (user.roles.includes(UserRoles.ADMIN) && user.companies[0]) {
            this.setSelectedCompany(user.companies[0].id);
            this.setSelectedCompanyName(user.companies[0].name);
          }
        })
      );
  }

  getUser(): any {
    const userJson = localStorage.getItem('user');
    if (userJson !== null) {
      try {
        return JSON.parse(userJson);
      } catch (e) {
        console.error('Error parsing user data from localStorage', e);
        return null;
      }
    }
    return null;
  }

  setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      console.log('Clearing access_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('selected_company');
      localStorage.removeItem('selected_company_name');
      this.logoutSubject.next();
      observer.complete();
    });
  }

  isUserLoggedIn() {
    return !!localStorage.getItem('access_token');
  }

  me() {
    return this.httpClient.get(`${environment.serviceUrl}/users/me`);
  }

  getCurrentUserCompany() {
    return this.httpClient.get(`${environment.serviceUrl}/companies/me`);
  }

  updateCurrentUserCompany(payload: Company) {
    return this.httpClient.put(`${environment.serviceUrl}/companies`, payload);
  }

  updateProfile(updateProfilePayload: { firstName: string; lastName: string }) {
    return this.httpClient.put(
      `${environment.serviceUrl}/users/me`,
      updateProfilePayload
    );
  }

  changePassword(changePasswordPayload: {
    oldPassword: string;
    newPassword: string;
    repeatNewPassword: string;
  }) {
    return this.httpClient.put(
      `${environment.serviceUrl}/users/change-password`,
      changePasswordPayload
    );
  }

  forgotPassword(forgotPasswordReq: any) {
    return this.httpClient.post<any>(
      `${environment.serviceUrl}/users/forgot-password`,
      {},
      {
        params: HttpUtils.getHttpParams(forgotPasswordReq),
      }
    );
  }

  resetPassword(resetPasswordReq: any) {
    return this.httpClient.post<any>(
      `${environment.serviceUrl}/users/reset-password`,
      resetPasswordReq
    );
  }

  emitGetUserAndSetCompaniesCall() {
    this.triggerGetUserAndSetCompanies.next();
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  setRedirectUrl(url: string | null) {
    this.redirectUrl = url;
  }

  setSelectedCompany(company: string) {
    localStorage.setItem('selected_company', company);
  }

  setSelectedCompanyName(company: string) {
    localStorage.setItem('selected_company_name', company);
  }

  getSelectedCompany(): string {
    return localStorage.getItem('selected_company') || '';
  }

  isMetaCompany(): boolean {
    const selectedCompany = localStorage.getItem('selected_company');
    return (
      selectedCompany !== null &&
      environment.meta_company_id.includes(selectedCompany)
    );
  }

  getSelectedCompanyName(): string {
    return localStorage.getItem('selected_company_name') || '';
  }

  isDevUser(): boolean {
    const devUsers = [
      'fa760924-a84e-4fdd-92b4-33965dd42f13',
      'f7cd61b8-0036-4dde-927d-7c5d6c436e8f',
      '988c8d19-bde3-4fdd-9d0b-8d65b9c0325d',
      'b8791467-06d2-4cc5-aac3-b5b5b20b8585',
      '05fdfe97-6217-45bd-b703-51773ddc7c71',
      '42d8d64d-7ca1-4886-a29b-8a132583040b',
      'aae44d89-ae3c-480b-8118-6deca3a8aa2f',
      'b61341ec-7144-49da-89e3-4e81e9426f8e',
      '70216c53-8e7d-43df-83b5-1d559a5d0503',
      '19f1c5ea-802d-4c9e-b049-35e4047fa462',
      'e34f17a5-34e3-4986-8bdd-a31732ea95e9',
    ];

    const userJson = localStorage.getItem('user');

    if (!userJson) {
      return false;
    }

    try {
      const user = JSON.parse(userJson);
      return devUsers.includes(user.id);
    } catch (error) {
      return false;
    }
  }

  isReviewUser(): boolean {
    const reviewUsers = [
      '8f42470b-ded7-4896-bd4e-534c5def2730',
      'fe90aa82-1dc4-4adf-8f4b-6333030e460c',
    ];

    const userJson = localStorage.getItem('user');

    if (!userJson) {
      return false;
    }

    try {
      const user = JSON.parse(userJson);
      return reviewUsers.includes(user.id);
    } catch (error) {
      return false;
    }
  }

  isUserVerified() {
    return this.httpClient.get(`${environment.serviceUrl}/verification/verify`);
  }

  requestVerification() {
    return this.httpClient.post(
      `${environment.serviceUrl}/verification/request-token`,
      {}
    );
  }

  validateTokenAfterVerification(token: string) {
    return this.httpClient.patch(
      `${environment.serviceUrl}/verification/confirm`,
      {},
      { params: { token } }
    );
  }
}
